.mobileChatContainer {
  height: 100%;
}

.mobileChatDetailWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mobileChatHeaderContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
}

.mobileChatInputContainer {
  padding: 5rem 0;
  padding-bottom: 0rem;
}
