.crawlWebisteContainer {
  border: 1px solid var(--visito-border-color);
  border-radius: 0.8rem;
  padding: 22vh 1.5rem;
  position: relative;
}

.skipCrawling {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
}
