.navbar {
  --bs-navbar-padding-y: 0rem !important;
}
.navbar-nav {
  --bs-nav-link-padding-y: 13px !important;
}
nav .dropdown-menu[data-bs-popper] {
  right: 0;
  left: unset;
}

nav .hidden {
  display: none;
}

nav .dropdown-menu {
  border-color: var(--visito-border-color);
}

.nav-link {
  color: var(--ion-color-dark) !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  margin-left: 0.2rem !important;
  margin-right: 0.2rem !important;
  border-bottom: 1.5px solid transparent;
  padding: 0.8rem 1rem !important;
}

.nav-link:hover {
  color: var(--visito-color-1) !important;
  background-color: var(--color-step-100);
}

.nav-link.active {
  color: var(--visito-color-1) !important;
  border-bottom: 1.5px solid var(--visito-color-1);
}

.nav-item.account-switcher .nav-link {
  padding: 0.3rem 0.8rem !important;
  background-color: var(--ion-color-step-50);
  border-radius: 7px;
}

.nav-item.light-account-switcher .nav-link {
  border: 1px solid var(--visito-chat-input-border);
  border-radius: 8px;
  padding: 8px 12px !important;
  margin-left: 0 !important;
}

.nav-item.light-account-switcher .nav-link:after {
  right: 16px;
  top: 16px;
  position: absolute;
}

.nav-item.light-account-switcher .dropdown-menu.show {
  max-height: 35vh;
  width: 100%;
  overflow: scroll;
}

.nav-item.account-switcher .dropdown-menu.show {
  max-height: 440px;
  overflow: scroll;
}

.nav-item.account-settings .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.59px;
  height: 33.59px;
  border: 1px solid var(--ion-color-step-100);
  border-radius: 50%;
  padding: 0 !important;
}

.nav-item.account-settings .nav-link::after {
  display: none;
}
.dropdown-header {
  color: var(--ion-color-step-600) !important;
}
nav {
  border-bottom: 1px solid var(--visito-border-color);
  z-index: 999 !important;
  background-color: var(--visito-background-color);
}

nav .badge {
  margin-left: 4px;
}
