.breadcrumbContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1.5rem;
  margin-left: -12px;
}

.totalsLabel {
  margin-bottom: 0;
  color: var(--ion-color-step-800);
  font-size: 0.85rem;
}

.filters {
  min-width: 365px;
}

.commonFilter {
  max-width: 130px;
}
