.authContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  @media screen and (min-width: 600px) {
    background-image: url("../../theme/images/login-background-pattern.svg");
    @media (prefers-color-scheme: dark) {
      background-image: url("../../theme/images/login-background-pattern-dark.svg");
    }
    background-size: auto; /* Ajusta el tamaño del fondo */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-position: center; /* Centra la imagen */
  }
  background-color: var(--ion-color-step-50);
}

.authHolder {
  width: 100%;
  height: 100%;
  text-align: left;
  align-items: center;
  justify-content: center;
  display: flex;
}

.authPanel {
  border: 1px var(--visito-border-color) solid;
  padding: 1.5rem;
  border-radius: 1rem;
  width: 440px;
  background-color: var(--ion-color-step-0);
  @media screen and (max-width: 600px) {
    width: 100%;
    border: none;
    background-color: transparent;
  }
}

.authPanel h1 {
  font-size: 22px;
  margin-bottom: 24px;
  text-align: center;
}

.authPanel h2 {
  font-size: 16px;
  color: var(--ion-color-step-600);
  font-weight: 400;
}

.inputHolder {
  margin-top: 1em;
}

.passwordHolder {
  position: relative;
}
.authForm label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7.5px;
}

.togglePassword {
  position: absolute;
  top: 70%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px; /* Adjust width and height according to icon size */
  height: 20px;
  background-image: url("../../theme/images/eye-password.svg"); /* URL of the eye icon */
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
