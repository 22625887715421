.chat-mobile-wrappe,
.empty-list,
.chat-list .empty-list {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contact-list .details,
.contact-list .profile,
.contact-list .contact {
  height: 47px;
}

.contact-list .half-item {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contact-list p {
  margin: 0;
}

.contact-list .profile {
  align-items: center;
  display: flex;
  margin-inline-end: 12px;
}

.contact-list .details {
  margin-inline-start: 8px;
  display: flex;
}

.contact-list .details .details-container {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
}

.contact-list .timestamp,
.contact-list .notifications-badge {
  justify-content: flex-end;
}

.contact-list .profile .profile-platform {
  width: 19px;
  height: 19px;
}

.contact-list .list-item {
  cursor: pointer;
}

.contact-list .conversation-preview {
  vertical-align: baseline;
  color: var(--ion-color-step-600);
  font-size: 0.9rem;
}

.contact-list .contact-name {
  font-size: 0.95rem;
  color: var(--ion-color-step-950);
  vertical-align: baseline;
}

.contact-list .contact-company {
  display: flex;
  align-items: center;
}

.contact-list .contact-company .badge {
  font-weight: 400;
  font-size: 13px;
}

.contact-list .contact-holder {
  height: 50%;
}

.contact-list .contact-holder span {
  display: inline;
  margin-right: 2px;
}

.contact-list .timestamp {
  font-size: 0.75rem;
  color: var(--ion-color-step-800);
}

.contact-list .notifications-badge {
  font-size: 11px;
  color: var(--ion-color-step-50);
  margin-left: 4px;
  border-radius: 12px;
  line-height: 11px;
}

@media (min-width: 600px) {
  @media (prefers-color-scheme: dark) {
    .contact-list .selected::part(native) {
      background-color: rgb(var(--ion-color-primary-rgb-subtle));
    }
  }
  .contact-list .selected::part(native) {
    background-color: var(--visito-color-2-light);
  }

  .contact-list .selected .contact-name {
    color: var(--visito-color-2);
  }

  .contact-list .list-item:hover .contact-name {
    color: var(--visito-color-2);
  }
}

@media (max-width: 600px) {
  .contact-list .contact-name {
    font-size: 1.05rem;
  }

  .contact-list .timestamp {
    font-size: 0.8rem;
  }
}
