.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mobileTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--ion-color-step-800);
  margin-bottom: 0;
}

.mobileDescription {
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--ion-color-step-600);
  margin-bottom: 0;
}

.pageTitle {
  font-size: 1.6em;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  padding-top: 0.75em;
  font-weight: 600;
  color: var(--ion-color-step-800);
}

.pageDescription {
  font-size: 0.95em;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: var(--ion-color-step-600);
  margin-bottom: 16px;
  margin-top: 8px;
}
