.playgroundWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.contextManagementWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
  padding: 1rem;
}

.chatWrapper {
  width: 40%;
  height: 100%;
  background-color: var(--ion-color-step-50);
  padding: 1rem;
}

.chatSkeleton {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: var(--ion-color-step-0);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.chatContainer {
  background-color: var(--ion-color-step-0);
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0.5rem;
  border: 1px solid var(--visito-border-color);
}

.chatDetailContainer {
  height: 100%;
}

.chatInputContainer {
  padding: 12px;
}

.chatHeaderContainer {
  border-bottom: 1px solid var(--visito-border-color);
}

.chatHeaderContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1rem;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.loaderContainer {
  margin-top: 5em;
  width: 250px;
}
