#list ._toolbarToggleItem_11eqz_187:last-child,
#styles
  ._toolbarGroupOfGroups_11eqz_198
  ._toolbarToggleSingleGroup_11eqz_203:last-child {
  display: none;
}
#styles
  ._toolbarGroupOfGroups_11eqz_198
  ._toolbarToggleSingleGroup_11eqz_203:nth-child(2) {
  display: none;
}

._selectContainer_11eqz_261 ._selectItem_11eqz_274:nth-child(2),
._selectContainer_11eqz_261 ._selectItem_11eqz_274:nth-child(4),
._selectContainer_11eqz_261 ._selectItem_11eqz_274:nth-child(5),
._selectContainer_11eqz_261 ._selectItem_11eqz_274:nth-child(6),
._selectContainer_11eqz_261 ._selectItem_11eqz_274:nth-child(7),
._selectContainer_11eqz_261 ._selectItem_11eqz_274:nth-child(8) {
  display: none;
}
.text-editor-extra {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.text-editor-extra .text-save {
  padding-left: 6px;
}
.text-editor-extra .action-buttons {
  display: flex;
  align-items: center;
}

._contentEditable_11eqz_352 h1,
._contentEditable_11eqz_352 h2,
._contentEditable_11eqz_352 h3,
._contentEditable_11eqz_352 h4,
._contentEditable_11eqz_352 h5 {
  font-size: 1.3rem;
}

._contentEditable_11eqz_352 {
  font-size: 0.9em;
}
