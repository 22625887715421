.chat .chat-detail-header-holder {
  display: flex;
  align-items: center;
}

.chat-title-container {
  display: flex;
  align-items: center;
}

.chat-title-container .chat-title-profile {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-left: 8px;
}

.chat-title-container .user-platform {
  width: 25px;
  height: 25px;
  min-width: 25px;
}

.chat-title-container .title-name {
  line-height: 17px;
}

.chat-title-container .title-company {
  line-height: 16px;
}

.chat-escalation-title-container {
  padding: 0.4rem 1.5rem 0.4rem 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.escalation-button-holder {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 0rem 0.5rem 0rem;
  position: sticky;
  bottom: 0;
}

.chat-item p {
  overflow: hidden;
}
.chat-item .chat-item-time {
  height: 19px;
}

.submit-mobile {
  padding: 0px 8px 20px 8px;
}

.pictures-modal {
  --height: 100%;
  --width: 100%;
}

ion-toolbar.escalation {
  --background: rgb();
}
