.dropdownContainer {
  min-width: 100px;
  height: auto;
  max-height: 350px;
  overflow: scroll;
  background-color: var(--visito-background-color);
  box-shadow: 0px 16px 32px -12px rgba(14, 18, 27, 0.1);
  border-radius: 16px;
  position: absolute;
  right: 0;
  z-index: 9000;
}

.dropdownTitle {
  max-width: 200px;
  overflow: auto;
  height: auto;
  padding: 0em 1em;
}

.dropdownBody {
  max-width: 200px;
  overflow: scroll;
  height: auto;
  padding: 1em;
}

.dropdownLabel {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 100%;
  overflow: hidden;
}

.btnHeader {
  border-radius: 10px !important;
  height: 36px;
  padding-inline: 0.75em !important;
}

.btnImageFilter {
  height: 20px;
  width: 15px;
  margin-right: 4px;
}

/*
const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;




const ActionButton = styled(Button)`
  color: #1890ff;
`;

*/
