.upload-modal {
}

.upload-modal.modal-desktop {
  --height: 90%;
  --width: 60%;
  --min-width: 820px;
  --min-height: 720px;
}

.upload-modal .upload-photo-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-modal .carousel-slider {
  max-height: 100%;
}

.upload-modal .upload-photo-modal,
.upload-modal .carousel-slider .slider-wrapper,
.upload-modal .carousel .slider {
  height: 100%;
}

.upload-modal .carousel .slide {
  display: flex;
  justify-content: center;
}
.upload-modal .carousel .slide img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}
.upload-modal .carousel .thumbs {
  display: flex;
  justify-content: center;
}

.upload-modal .carousel .thumbs-wrapper {
  margin: 0;
}
.upload-modal .thumbs {
  display: flex;
  justify-content: center;
}
.upload-modal .thumbs img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.upload-modal .carousel-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 55px;
}

.upload-photo-modal .input-holder {
  padding: 1em;
  position: fixed;
  bottom: 0;
  display: flex;
  gap: 1em;
  width: 100%;
  justify-items: center;
}

.upload-modal .thumbs li {
  display: flex;
}

.upload-photo-modal .input-holder .input-wrapper {
  width: 350px;
  display: flex;
  gap: 1em;
}
/*
@media (max-width: 600px) {
  .upload-photo-modal {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
  }


  .upload-photo-modal .img-holder {
    overflow: scroll;
  }
  .upload-photo-modal .img-holder img {
  }
  .upload-photo-modal .input-holder {
    padding: 1em;
    padding-bottom: 2em;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--ion-color-step-50);
  }
}
@media (min-width: 600px) {
  .upload-photo-modal img {
    max-height: "300px";
    max-width: "240px";
  }
}
*/
