.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.7rem;
  width: 100%;
}

.headerToggle {
  padding-right: 12px;
}

.phone {
  color: var(--ion-color-step-600);
}
