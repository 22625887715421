.navbanner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navbanner a {
  text-decoration: underline !important;
}

.navbanner.trial,
ion-toolbar .navbanner.trial {
  color: var(--visito-color-warning);
  background-color: RGBA(
    var(--visito-color-bg-warning-subtle-rgb),
    var(--bs-bg-opacity, 1)
  );
}

.navbanner.payment,
ion-toolbar .navbanner.payment {
  color: var(--visito-color-danger);
  background-color: RGBA(
    var(--visito-color-bg-danger-subtle-rgb),
    var(--bs-bg-opacity, 1)
  );
}

ion-toolbar.navbanner.trial {
  --background: RGBA(
    var(--visito-color-bg-warning-subtle-rgb),
    var(--bs-bg-opacity, 1)
  );
  --color: var(--visito-color-warning);
}

ion-toolbar.navbanner.payment {
  --background: RGBA(
    var(--visito-color-bg-danger-subtle-rgb),
    var(--bs-bg-opacity, 1)
  );
  --color: var(--visito-color-danger);
}

.navbanner-item {
  height: 40px;
  display: flex;
  align-items: center;
  padding-inline: 0.4em;
  @media screen and (max-width: 600px) {
    height: 25px;
    padding-inline: 0.2em;
  }
}
