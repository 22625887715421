.tableContainer {
  /* max-height: calc(100vh - 365px); */
  overflow-y: auto;
  margin-bottom: 2rem;
}

.theadFixed {
  position: sticky;
  top: 0;
}

.tableBody {
  font-size: 0.8rem;
}

.tableHeader {
  background-color: var(--ion-color-step-50);
}

.tableTitle {
  color: var(--ion-color-step-750);
  font-size: 0.9rem;
  font-weight: normal;
  padding: 0.5rem 1rem !important;
}

.tdFullWidth {
  width: 1%;
  white-space: nowrap;
}

.commonTd {
  padding: 1rem !important;
}
