.settings-wrapper {
  display: flex;
  height: 100%;
}

.settings-wrapper .menu-holder {
  min-width: 200px;
  height: 100%;
  border-right: 1px solid var(--visito-border-color);
  padding: 2em;
}

.content-holder {
  padding: 2em 1em;
  width: 100%;
}

.content-holder .settings-presentation {
  padding-top: 20px;
  min-width: 500px;
}

.content-holder .settings-w-presentation {
  padding-top: 0px;
  width: 100%;
}
