.contact-details-container {
  height: 100%;
  padding: 1.5em;
  user-select: text;
}

.contact-details-container .contact-details-profile {
  border-bottom: 1px solid var(--visito-border-color);
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.contact-details-container .booking-id {
  display: flex;
  align-items: center;
}

.contact-details-container .booking-id .badge {
  margin-left: 10px;
  margin-bottom: 2px;
}
