.btn {
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--visito-chat-input-border);
  background-color: var(--visito-background-color);
  border-radius: 0.3rem;
  outline: none;
}

.btnActive {
  background-color: var(--ion-color-step-50);
  pointer-events: none;
  cursor: default;
}

.actionDisable {
  pointer-events: none;
  cursor: default;
  color: var(--ion-color-step-350);
}