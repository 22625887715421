.badgeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 0.45rem;
  padding: 4px 6px;
  border: 1px solid var(--visito-chat-input-border);
  font-size: 0.8rem;
  color: var(--ion-color-step-700);
  width: max-content;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.danger {
  background-color: var(--visito-color-danger);
}

.success {
  background-color: var(--visito-color-active);
}
