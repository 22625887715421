.settings-navigation-container .section-title {
  display: flex;
  align-items: center;
  color: var(--ion-color-step-700);
  font-size: 15px !important;
  margin-bottom: 10px;
}
.settings-navigation-container .section-title svg {
  margin-right: 8px;
}
.settings-navigation-container .settings-navigation {
  margin-top: 20px;
}

.settings-navigation-container h1 {
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 1.5em;
}

.settings-navigation-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.settings-navigation-container li {
  border-radius: 0.4rem;
  font-weight: 500;
  font-size: 15px !important;
  margin-bottom: 5px;
  padding: 0.2rem 1rem;
}

.settings-navigation-container a {
  color: var(--ion-color-dark);
}

.settings-navigation-container li.selected {
  background-color: var(--ion-color-step-50);
}

.settings-navigation-container a:hover {
  color: var(--visito-color-1);
}
