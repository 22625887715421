.divider {
  height: 1px;
  width: 100%;
  background-color: var(--visito-chat-input-border);
}

.arrowIcon {
  position: absolute;
  top: 50%;
  right: 4px;
  color: var(--ion-color-step-750);
}

.eventDescription,
.timeIcon {
  color: var(--ion-color-step-600);
}

.alertBtn {
  font-size: 0.8rem !important;
  text-transform: none !important;
  text-align: center !important;
  border-radius: 0.5rem !important;
  line-height: 1rem !important;
  width: max-content !important;
  flex: none !important;
  min-width: 100px !important;
}

.alertConfirm {
  background-color: #df1c41 !important;
  color: #fff !important;
}

.alertConfirm {
  background-color: var(--visito-color-1) !important;
  color: #fff !important;
}

.alertCancel {
  border: 1px solid var(--visito-chat-input-border) !important;
  background-color: var(--visito-background-color) !important;
  color: var(--ion-color-step-650) !important;
}
