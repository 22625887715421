.chat-mobile-wrapper .searchbar-input {
  background: var(--ion-color-step-0);
  font-size: 16px;
}

.searchbar-container {
  padding: 6px 16px 0px 16px;
}

.searchbar-container ion-searchbar {
  height: auto;
  contain: unset;
  padding: 0;
}

.chat-mobile-wrapper .chat-list-items-holder {
  height: 100%;
  overflow: scroll;
}

.chat-mobile-wrapper .searchbar-search-icon.sc-ion-searchbar-ios {
  width: 18px;
}

.chat-interface {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-interface .chat-interface-nav {
  z-index: 100;
  background-color: var(--ion-color-step-50);
  border-bottom: 1px solid var(--ion-color-step-100);
}

.chat-interface .chat-interface-nav .chat-interface-nav-review {
  padding: 0px 0px 9px 16px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.chat-interface-header .user-company .badge {
  font-weight: 400;
  font-size: 13px;
}

@media (prefers-color-scheme: dark) {
  .chat-interface .chat-interface-nav {
    background-color: var(--ion-color-step-50);
  }
}

.chat-interface .sc-ion-searchbar-ios-h {
  padding-bottom: 6px;
  padding-top: 6px;
  padding-right: 6px;
  padding-left: 6px;
  height: 45px;
}
