/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --border-color: #e3e7f6;
  --visito-border-color: var(--border-color);
  /** branding **/
  --visito-color-1: #007a61;
  --visito-color-1-hover: #006e57;
  --visito-color-1-rgb: 0, 122, 97;
  --visito-color-1-subtle: #b1ffef; /* @ 80% lighther */
  --visito-color-1-light: #effaf6; /* @ 90% lighther */

  --visito-color-2: #21326b;
  --visito-color-2-hover: #1f2f65;
  --visito-color-2-rgb: 33, 50, 107;
  --visito-color-2-subtle: #ecf2ff; /* @ 80% lighther */
  --visito-color-2-light: #ecf2ff; /* @ 90% lighther */
  --visito-color-2-light-rgb: 227, 231, 246; /* @ 90% lighther */

  --visito-color-3: #71d8bf;
  --visito-color-3-rgb: 113, 216, 191;
  --visito-color-3-subtle: #e3f7f2; /* @ 80% lighther */

  --visito-color-4: #f8f9fa;
  --visito-color-4-hover: #e9edf0; /* @ 5% darker */

  --visito-color-black: #131315;
  --visito-color-black-rgb: 19, 19, 21;

  --visito-color-warning: #a67a0a;
  --visito-color-bg-warning-rgb: 255, 245, 218;
  --visito-color-bg-warning-subtle-rgb: 254, 247, 236;

  --visito-color-danger: #df1c41;
  --visito-color-bg-danger-rgb: 223, 28, 65;
  --visito-color-bg-danger-subtle-rgb: 254, 236, 240;

  --visito-color-active: #38c793;
  --visito-color-danger: #df1c41;
  --visito-color-danger-light: #ffebec;
  --visito-color-purple: #7d52f4;
  --visito-color-purple-light: #efebff;
  --visito-color-purple-light-rgb: 239, 235, 255;
  --visito-color-inactive: #cacfd8;

  --visito-shadow-header: #999;

  --visito-chat-input-border: var(--visito-color-2-light);

  --visito-color-success: #e4fbf8;
  --visito-color-success-border: #0b4627;

  /** primary **/
  --ion-color-primary: #5d87ff; /** Was custom changed **/
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-rgb-subtle: 56, 128, 255, 0.1;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: var(--visito-color-3); /* #2dd36f; */
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffae1f;
  --ion-color-warning-rgb: 255, 174, 31;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #fa896b;
  --ion-color-danger-rgb: 250, 137, 107;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** ligh-suttle-blue **/
  --ion-color-step-0: #ffffff;
  --ion-color-step-50: #f2f2f2;
  --ion-color-step-50-rgb: 242, 242, 242;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-100-rgb: 230, 230, 230;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-150-rgb: 217, 217, 217;
  --ion-color-step-200: #cccccc;
  --ion-color-step-200-rgb: 204, 204, 204;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-250-rgb: 191, 191, 191;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-300-rgb: 179, 179, 179;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-350-rgb: 166, 166, 166;
  --ion-color-step-400: #999999;
  --ion-color-step-400-rgb: 153, 153, 153;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-450-rgb: 140, 140, 140;
  --ion-color-step-500: #808080;
  --ion-color-step-500-rgb: 128, 128, 128;
  --ion-color-step-550: #737373;
  --ion-color-step-550-rgb: 115, 115, 115;
  --ion-color-step-600: #666666;
  --ion-color-step-600-rgb: 102, 102, 102;
  --ion-color-step-650: #595959;
  --ion-color-step-650-rgb: 89, 89, 89;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-700-rgb: 77, 77, 77;
  --ion-color-step-750: #404040;
  --ion-color-step-750-rgb: 64, 64, 64;
  --ion-color-step-800: #333333;
  --ion-color-step-800-rgb: 51, 51, 51;
  --ion-color-step-850: #262626;
  --ion-color-step-850-rgb: 38, 38, 38;
  --ion-color-step-900: #191919;
  --ion-color-step-900-rgb: 25, 25, 25;
  --ion-color-step-950: #0d0d0d;
  --ion-color-step-950-rgb: 13, 13, 13;
  --ion-color-step-1000: #000000;
  --ion-color-step-1000-rgb: 0, 0, 0;

  /* Visito */
  --visito-color: #0f98b3;
  --visito-background-color: #fff;
  --visito-title-color: #505050;

  /* Paddings */
  --ion-padding: 0px;
  --ion-background-color: #fff;

  /* Bootstrap */
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #fa896b;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffae1f;
  --bs-green: #13deb9;
  --bs-teal: #20c997;
  --bs-cyan: #539bff;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #2a3547;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f6f9fc;
  --bs-gray-200: #eaeff4;
  --bs-gray-300: #dfe5ef;
  --bs-gray-400: #7c8fac;
  --bs-gray-500: #5a6a85;
  --bs-gray-600: #2a3547;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #5d87ff;
  --bs-secondary: #49beff;
  --bs-success: #13deb9;
  --bs-info: #539bff;
  --bs-warning: #ffae1f;
  --bs-danger: #fa896b;
  --bs-light: #f6f9fc;
  --bs-dark: #2a3547;
  --bs-muted: #5a6a85;
  --bs-indigo: #6610f2;
  --bs-light-indigo: #ebf3fe;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 73, 190, 255;
  --bs-success-rgb: 19, 222, 185;
  --bs-info-rgb: 83, 155, 255;
  --bs-warning-rgb: 255, 174, 31;
  --bs-danger-rgb: 250, 137, 107;
  --bs-light-rgb: 246, 249, 252;
  --bs-dark-rgb: 42, 53, 71;
  --bs-muted-rgb: 90, 106, 133;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-light-indigo-rgb: 235, 243, 254;

  --bs-link-hover-color: var(--visito-color-2);
  --bs-link-color: var(--visito-color-2);

  --instagram-logo-url: url("../theme/images/instagram-logo.svg");
  --whatsapp-logo-url: url("../theme/images/whatsapp-logo.svg");
  --messenger-logo-url: url("../theme/images/messenger-logo.svg");
  --airbnb-logo-url: url("../theme/images/airbnb-logo.svg");
  --web-logo-url: url("../theme/images/web-logo.svg");
  --voice-logo-url: url("../theme/images/voice-logo.svg");
  --cloudbeds-logo-url: url("../theme/images/cloudbeds-logo.svg");
  --siteminder-logo-url: url("../theme/images/siteminder-logo.jpg");
  --guesty-logo-url: url("../theme/images/guesty-logo.svg");
  --hostaway-logo-url: url("../theme/images/hostaway-logo.svg");
  --mirai-logo-url: url("../theme/images/mirai-logo.png");

  --bs-navbar-padding-y: 0;

  --ion-item-background: #fff;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --border-color: #30363d;
    --visito-border-color: var(--border-color);
    --visito-color: #fff;
    --visito-background-color: #202124;

    --visito-color-1: #d8fff7;
    --visito-color-1-hover: #c0fff2;
    --visito-color-1-subtle: #005645; /* @ 80% lighther */
    --visito-color-1-light: #006e57; /* @ 90% lighther */

    --visito-color-2: #c6cfee;
    --visito-color-2-subtle: #21326b; /* same */
    --visito-color-2-light: #192550; /* @ 5% darker */
    --visito-color-2-light-rgb: 25, 37, 80;

    --visito-color-3: #3dcaa8; /* @ 20 darker */
    --visito-color-3-subtle: #0e342b; /* @ 80% */

    --visito-color-4: #15191d; /* @ 80 darker */
    --visito-color-4-hover: #29323a; /* @ 5% ligher */

    --visito-shadow-header: black;

    --visito-color-warning: #dacb92;
    --visito-color-bg-warning-rgb: 56, 56, 49;
    --visito-color-bg-warning-subtle-rgb: 79, 71, 37;

    --visito-color-bg-danger-rgb: 79, 3, 20;
    --visito-color-bg-danger-subtle-rgb: 79, 3, 20;

    --visito-color-purple: #bfb7fd;
    --visito-color-purple-light-rgb: 37, 38, 75;

    --visito-chat-input-border: #30363d;

    --ion-color-primary: #afccff;
    --ion-color-primary-rgb: 175, 204, 255;
    --ion-color-primary-rgb-subtle: 24, 33, 52;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #9ab4e0;
    --ion-color-primary-tint: #b7d1ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: var(--visito-color-3); /* #2dd36f; */
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
    --bs-link-hover-color: var(--visito-color-2);
    --bs-link-color: var(--visito-color-2);

    --ion-color-step-0: #000000;
    --ion-color-step-0-rgb: 0, 0, 0;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-50-rgb: 13, 13, 13;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-100-rgb: 26, 26, 26;
    --ion-color-step-150: #262626;
    --ion-color-step-150-rgb: 38, 38, 38;
    --ion-color-step-200: #333333;
    --ion-color-step-200-rgb: 51, 51, 51;
    --ion-color-step-250: #404040;
    --ion-color-step-250-rgb: 64, 64, 64;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-300-rgb: 77, 77, 77;
    --ion-color-step-350: #595959;
    --ion-color-step-350-rgb: 89, 89, 89;
    --ion-color-step-400: #666666;
    --ion-color-step-400-rgb: 102, 102, 102;
    --ion-color-step-450: #737373;
    --ion-color-step-450-rgb: 115, 115, 115;
    --ion-color-step-500: #808080;
    --ion-color-step-500-rgb: 128, 128, 128;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-550-rgb: 140, 140, 140;
    --ion-color-step-600: #999999;
    --ion-color-step-600-rgb: 153, 153, 153;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-650-rgb: 166, 166, 166;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-700-rgb: 179, 179, 179;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-750-rgb: 191, 191, 191;
    --ion-color-step-800: #cccccc;
    --ion-color-step-800-rgb: 204, 204, 204;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-850-rgb: 217, 217, 217;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-900-rgb: 230, 230, 230;
    --ion-color-step-950: #f2f2f2;
    --ion-color-step-950-rgb: 242, 242, 242;
    --ion-color-step-1000: #ffffff;
    --ion-color-step-1000-rgb: 255, 255, 255;

    --instagram-logo-url: url("../theme/images/instagram-logo-dark.svg");
    --whatsapp-logo-url: url("../theme/images/whatsapp-logo-dark.svg");
    --messenger-logo-url: url("../theme/images/messenger-logo-dark.svg");
    --airbnb-logo-url: url("../theme/images/airbnb-logo-dark.svg");
    --web-logo-url: url("../theme/images/web-logo-dark.svg");
    --voice-logo-url: url("../theme/images/voice-logo-dark.svg");
    --cloudbeds-logo-url: url("../theme/images/cloudbeds-logo-dark.svg");
    --guesty-logo-url: url("../theme/images/guesty-logo-dark.svg");
    --hostaway-logo-url: url("../theme/images/hostaway-logo-dark.svg");
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-0: #000000;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-color-step-1000: #ffffff;
    --ion-item-background: #000000;
    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
  @media screen and (min-width: 600px) {
    body {
      --ion-border-color: var(--border-color);
      --ion-item-border-color: var(--border-color);
    }
  }
}

@media screen and (min-width: 600px) {
  :root {
    --visito-border-color: var(--border-color);
    --ion-border-color: var(--border-color);
    --ion-item-border-color: var(--border-color);
  }
}
