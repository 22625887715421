.editorContainer {
  height: 100%;
  overflow-y: scroll;
  border: 1px solid var(--visito-border-color);
  border-radius: 7px;
}

.updateBtn {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-radius: 0.4rem;
  background-color: var(--ion-color-step-0);
  color: var(--ion-color-step-750);
  border: 1px solid var(--ion-color-step-150);
  padding: 0.5rem 0.7rem;
  font-size: 0.9rem;
  text-decoration: none;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--visito-border-color);
}
