.container {
  border-radius: 8px;
  padding: 8px 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
}

.textarea {
  background-color: unset;
  resize: none !important;
  overflow: hidden;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
  font-weight: 400;
  line-height: 1.5;
  color: var(--ion-color-step-900);
  background-color: var(--ion-color-step-0);
  background-clip: padding-box;
  border: 1px solid var(--ion-color-step-100);
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: calc(1.5em + 0.75rem + 2px);
  caret-color: var(--visito-color-1);
}

.textarea:focus {
  background-color: var(--ion-color-step-0);
  border-color: var(--ion-color-step-200);
  outline: 0;
  box-shadow: none;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  margin-left: auto;
  height: 38px;
}

.actions.closed {
  display: none;
}

.actions.active {
  display: flex;
}

.actionBtn {
  min-height: 34px;
  min-width: 34px;
}

.fabContainer {
  position: relative;
  overflow: visible;
  width: 100%;
}

.fabMenu {
  position: absolute;
  bottom: 0px;
  left: 0;
  display: flex;
  width: auto;
  flex-direction: column;
  gap: 5px;
  border: 1px solid var(--visito-border-color);
  border-radius: 5px;
  padding: 5px;
  background-color: var(--ion-color-step-0);
}

.fabBtn {
  justify-content: flex-start !important;
}

.replyToContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.replyToMessage {
  display: flex;
  width: 100%;
}

.replyToAction {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
