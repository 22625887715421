.filtersContainer {
  border-radius: 0.35rem;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
  border: 1px solid var(--visito-chat-input-border);
  width: max-content;
  background-color: var(--ion-item-background);
}

.commonFilterSelect {
  min-height: auto;
  padding-right: 0.8rem;
  font-size: 0.9rem;
  --highlight-color: transparent !important;
}

.dateWrapper {
  width: 100%;
  border-left: 1px solid var(--visito-chat-input-border) !important;
  padding: 0.25rem 0 !important;
  padding-left: 0.8rem !important;
  display: flex;
  align-items: center;
  gap: 4px;
}

.dateInput {
  border: none;
  width: 100%;
  color: var(--ion-color-step-800);
  outline: none;
  font-size: 0.9rem;
  background-color: unset;
}
