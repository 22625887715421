.statusBadge {
  border-radius: 7px;
  border: 1px solid var(--visito-color-active);
  background-color: var(--visito-color-1-light);
  padding: 0.15em 0.25em;
  font-size: 0.7em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  text-wrap: nowrap;
}

.activeIcon {
  color: var(--visito-color-active);
}

.inactiveIcon {
  color: var(--visito-color-inactive);
}
