.container {
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--visito-color-2-light);
  width: 100%;
}

.content {
  border-left: 3px solid var(--visito-color-1);
  width: 100%;
  display: flex;
  gap: 0.5em;
  padding: 0.5em;
  max-height: 70px;
}
.icon {
  font-size: 0.85rem;
  align-content: center;
}
.text {
  font-size: 0.85rem;
  align-content: center;
  width: 100%;
}
.image {
  width: auto;
}
.image img {
  border-radius: 8px;
  height: 100%;
  float: right;
}

.left {
  background-color: var(--visito-color-2-light);
  text-align: left;
  filter: brightness(95%);
}

.right {
  background-color: var(--ion-color-light);
  text-align: left;
  filter: brightness(95%);
}
