.visualization {
  display: none;
  bottom: 4px;
  right: 8px;
  padding: 0px;
  border-radius: 16px;
  z-index: 10;
  gap: 2px;
  width: 150px;

  display: flex;
}

.callActive {
  color: var(--ion-color-step-50) !important;
  background-color: var(--visito-color-danger) !important;
  border-color: var(--visito-color-danger) !important;
}

.visualization-entry {
  position: relative;
  display: flex;
  align-items: center;
  height: 31px;
  width: auto;
  gap: 4px;
  &.client {
    color: var(--visito-color-1);
  }
  &.server {
    color: var(--visito-color-2);
  }
  canvas {
    width: 100%;
    height: 100%;
    color: currentColor;
  }
}
