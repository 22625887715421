.stepListItem {
  display: flex;
  margin-bottom: 1rem;
}
.stepListItemIcon {
  padding-right: 12px;
}
.stepListItemDescription {
  width: 100%;
}

.stepListItemCta {
  align-self: center;
  min-width: 80px;
  text-align: right;
}

.filters {
  min-width: 365px;
}

.skip {
  float: right;
}

.filterDate {
  max-width: 135px;
}

.filterAlone {
  min-height: 34px;
  min-width: 180px;
}

.stats {
  min-height: 500px;
}
.statsWidgetNumber {
  font-size: 2rem;
  font-weight: 800;
}
