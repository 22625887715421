.headerContainer {
  border-bottom: 1px solid var(--visito-border-color) !important;
  padding: 1rem 1.5em !important;
}

.webChatWrapper {
  padding-top: 68px;
  height: 100%;
  width: 60vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.webChatWrapperMobile {
  padding-top: 5rem;
  padding-bottom: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
