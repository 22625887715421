nav.navbar-auth,
nav.navbar-auth-footer {
  border-bottom: none;
  z-index: 1 !important;
  background-color: transparent;
  padding: 1.5em;
}
@media screen and (max-width: 600px) {
  .navbar-auth .container-fluid {
    justify-content: center !important;
  }
  .navbar-auth {
    margin-top: 50px;
  }
}

.nav-copyright {
  font-size: 1em;
  color: var(--ion-color-step-500);
}

.nav-language {
  font-size: 1em;
  color: var(--ion-color-step-500);
}
.nav-language a {
  margin-left: 1em;
  color: var(--ion-color-step-500);
  cursor: pointer;
}

.nav-language a.active {
  text-decoration: none;
}
